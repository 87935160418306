import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { 
  TextField, 
  Container, 
  Box, 
  Typography, 
  Paper, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid, 
  Button, 
  AppBar, 
  Toolbar, 
  Checkbox,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  IconButton,
  Divider,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import CategoryIcon from '@mui/icons-material/Category';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const EventTable = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDate, setStartDate] = useState(null); // Start date filter
  const [endDate, setEndDate] = useState(null); // End date filter

  useEffect(() => {
    fetch('https://e-calendar.rmutp.ac.th/api/event_list', {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        const formattedEvents = data.map(event => ({
          id: event.event_id,
          title: event.event_name,
          event_name: event.event_name,
          event_type: event.event_type,
          event_categories: event.event_categories,
          event_department: event.event_department,
          event_place: event.event_place,
          event_detail: event.event_detail,
          event_remark: event.event_remark,
          event_link: event.event_link,
          start: event.event_start_datetime,
          end: event.event_end_datetime
        }));
  
        const sortedEvents = formattedEvents.sort((a, b) => moment(a.start).toDate() - moment(b.start).toDate());
  
        setEvents(sortedEvents);
      })
      .catch(error => {
        console.error('Error fetching events:', error);
      });
  }, []);

  const filteredEvents = events.filter(event => {
    if (!showAllEvents) {
      const startOfCurrentMonth = moment().startOf('month');
      return moment(event.start).isSameOrAfter(startOfCurrentMonth);
    }
    return true;
  }).filter(event =>
    event.event_name.toLowerCase().includes(filterText.toLowerCase()) &&
    (selectedType === '' || event.event_type === selectedType) &&
    (selectedCategory === '' || event.event_categories === selectedCategory) &&
    (selectedDepartment === '' || event.event_department === selectedDepartment) &&
    (!startDate || moment(event.start).isSameOrAfter(startDate, 'day')) &&
    (!endDate || moment(event.end).isSameOrBefore(endDate, 'day'))
  );

  const columns = [
    { 
      name: 'วันที่', 
      selector: row => moment(row.start).format('DD/MM/YYYY'), 
      sortable: true,
      sortFunction: (a, b) => moment(a.start).toDate() - moment(b.start).toDate(),
    },
    { 
      name: 'เวลา', 
      selector: row => `${moment(row.start).local().format('HH:mm')} - ${moment(row.end).local().format('HH:mm')}`, 
      sortable: false 
    },
    { name: 'ชื่อกิจกรรม', selector: row => row.event_name, sortable: true },
    {
      name: 'รูปแบบกิจกรรม',
      selector: row => row.event_type,
      sortable: true,
      cell: row => (
        <span style={{
          color: 'white',
          backgroundColor: getEventTypeColor(row.event_type),
          padding: '5px',
          borderRadius: '5px',
          textAlign: 'center',
          display: 'inline-block',
          width: '100%',
        }}>
          {row.event_type}
        </span>
      ),
    },
    { name: 'ประเภท/หมวดหมู่', selector: row => row.event_categories, sortable: true },
    { name: 'หน่วยงานที่รับผิดชอบ', selector: row => row.event_department, sortable: true },
  ];

  const getEventTypeColor = (eventType) => {
    switch (eventType) {
      case 'ประชุม':
        return '#2ec4b6'; 
      case 'อบรม':
        return '#98c1d9'; 
      case 'สัมมนา':
        return '#a7c957'; 
      case 'กิจกรรม':
        return '#ffbf69'; 
      default:
        return '#ff99c8'; 
    }
  };

  const conditionalRowStyles = [
    {
      when: row => moment(row.end).isBefore(moment()),
      style: {
        backgroundColor: '#f0f0f0',
        color: '#999999',
      },
    },
  ];

  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      'วันที่', 
      'เวลา', 
      'ชื่อกิจกรรม', 
      'รูปแบบกิจกรรม', 
      'ประเภท/หมวดหมู่', 
      'หน่วยงานที่รับผิดชอบ', 
      'รายละเอียด', 
      'หมายเหตุ', 
      'ลิงค์'
    ];
    csvRows.push(headers.join(','));
  
    filteredEvents.forEach(event => {
      const row = [
        moment(event.start).format('DD/MM/YYYY'),
        `${moment(event.start).local().format('HH:mm')} - ${moment(event.end).local().format('HH:mm')}`,
        event.event_name,
        event.event_type,
        event.event_categories,
        event.event_department,
        event.event_detail || 'ไม่มีรายละเอียด',
        event.event_remark || 'ไม่มีหมายเหตุ',
        event.event_link || 'ไม่มีลิงค์'
      ];
      csvRows.push(row.join(','));
    });
  
    const csvString = '\uFEFF' + csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'events.csv');
    a.click();
  };

  const handleRowClick = (row) => {
    setSelectedEvent(row);
    setDialogOpen(true);
  };

  const handleClearFilters = () => {
    setFilterText('');
    setSelectedType('');
    setSelectedCategory('');
    setSelectedDepartment('');
    setStartDate(null);
    setEndDate(null);
    setShowAllEvents(false);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }} 
            onClick={() => navigate('/')}
          >
            ระบบปฏิทินกลาง มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
          </Typography>
          <Button color="inherit" onClick={() => navigate('/')}>กลับไปหน้าปฏิทิน</Button>
          <Button color="inherit" onClick={() => navigate('/admin-login')}>เข้าสู่ระบบ</Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: 4 }}>
        <Box sx={{ marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                label="ค้นหากิจกรรม"
                variant="outlined"
                fullWidth
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>รูปแบบกิจกรรม</InputLabel>
                <Select
                  value={selectedType}
                  onChange={e => setSelectedType(e.target.value)}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  <MenuItem value="ประชุม">ประชุม</MenuItem>
                  <MenuItem value="อบรม">อบรม</MenuItem>
                  <MenuItem value="สัมมนา">สัมมนา</MenuItem>
                  <MenuItem value="กิจกรรม">กิจกรรม</MenuItem>
                  <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>ประเภท/หมวดหมู่</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={e => setSelectedCategory(e.target.value)}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  <MenuItem value="ทั่วไป">ทั่วไป</MenuItem>
                  <MenuItem value="วันสำคัญ">วันสำคัญ</MenuItem>
                  <MenuItem value="ปฏิทินการศึกษา">ปฏิทินการศึกษา</MenuItem>
                  <MenuItem value="วันหยุดราชการประจำปี">วันหยุดราชการประจำปี</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>หน่วยงานที่รับผิดชอบ</InputLabel>
                <Select
                  value={selectedDepartment}
                  onChange={e => setSelectedDepartment(e.target.value)}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                  <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                  <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                  <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                  <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                  <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                  <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                  <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                  <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                  <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                  <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                  <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                  <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                  <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                  <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                  <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                  <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                  <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                  <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                  <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                  <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                  <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                  <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                  <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                  <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  label="วันที่เริ่มต้น"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  slotProps={{ textField: { fullWidth: true } }} // ใช้ slotProps เพื่อปรับ TextField
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <DatePicker
                  label="วันที่สิ้นสุด"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  slotProps={{ textField: { fullWidth: true } }} // ใช้ slotProps เพื่อปรับ TextField
                />
              </Grid>
            </LocalizationProvider>

            {/*<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>*/}
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAllEvents}
                    onChange={e => setShowAllEvents(e.target.checked)}
                    color="primary"
                  />
                }
                label="แสดงข้อมูลทั้งหมด"
              />
              <Button variant="outlined" color="secondary" onClick={handleClearFilters} sx={{ marginRight: 1 }}>
                รีเซ็ตตัวกรอง
              </Button>
              <Button variant="outlined" color="primary" onClick={downloadCSV}>ดาวน์โหลด CSV</Button>
              </Grid>
            </Grid>
          {/*</Grid>*/}
        </Box>

        <Paper elevation={3}>
          <DataTable
            columns={columns}
            data={filteredEvents}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            striped
            noHeader
            onRowClicked={handleRowClick}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Paper>
      </Container>

      {selectedEvent && (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
          <DialogTitle>
            {selectedEvent.event_name}
            <IconButton aria-label="close" onClick={() => setDialogOpen(false)} style={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CalendarTodayIcon sx={{ marginRight: 1, color: '#42a5f5' }} />
                <Typography variant="body1" color="textPrimary">
                  วันที่: {moment(selectedEvent.start).format('DD/MM/YYYY')} เวลา: {moment(selectedEvent.start).local().format('HH:mm')} - {moment(selectedEvent.end).local().format('HH:mm')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EventIcon sx={{ marginRight: 1, color: '#42a5f5' }} />
                <Typography variant="body1" color="textPrimary">
                  {selectedEvent.event_detail ? selectedEvent.event_detail : 'ไม่มีรายละเอียด'}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PlaceIcon sx={{ marginRight: 1, color: '#ff7043' }} />
                <Typography variant="body1" color="textPrimary">
                  สถานที่: {selectedEvent.event_place || 'ไม่ระบุสถานที่'}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CategoryIcon sx={{ marginRight: 1, color: '#66bb6a' }} />
                <Typography variant="body1" color="textPrimary">
                  ประเภท: {selectedEvent.event_type}
                </Typography>
                <Typography variant="body1" color="textPrimary" style={{ marginLeft: '8px' }}>
                  หมวดหมู่: {selectedEvent.event_categories}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BusinessIcon sx={{ marginRight: 1, color: '#ffa726' }} />
                <Typography variant="body1" color="textPrimary">
                  หน่วยงาน: {selectedEvent.event_department}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DescriptionIcon sx={{ marginRight: 1, color: '#ff7043' }} />
                <Typography variant="body1" color="textPrimary">
                  หมายเหตุ: {selectedEvent.event_remark || 'ไม่มีหมายเหตุ'}
                </Typography>
              </Box>
              <Divider />
              {selectedEvent?.event_link && selectedEvent.event_link.trim() !== '' && selectedEvent.event_link !== '-' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                <Typography variant="body1" color="textPrimary">
                  ลิงค์: <a 
                    href={selectedEvent.event_link.startsWith('http://') || selectedEvent.event_link.startsWith('https://') 
                          ? selectedEvent.event_link 
                          : `https://${selectedEvent.event_link}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ color: '#1976d2', textDecoration: 'none' }}
                  >
                    ข้อมูลเพิ่มเติม
                  </a>
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                <Typography variant="body1" color="textPrimary">
                  ลิงค์: -
                </Typography>
              </Box>
            )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">ปิด</Button>
          </DialogActions>
        </Dialog>
      )}

      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="body1">© 2024 ระบบปฏิทินกลาง - มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร</Typography>
      </Box>
    </div>
  );
};

export default EventTable;
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'moment/locale/th';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AppBar, Toolbar, Typography, Container, Paper, Box, IconButton, Collapse, Grid, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CategoryIcon from '@mui/icons-material/Category';
import LabelIcon from '@mui/icons-material/Label';
import BusinessIcon from '@mui/icons-material/Business';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

moment.locale('th');
const localizer = momentLocalizer(moment);

const formats = {
  dayFormat: (date, culture, localizer) => moment(date).format('ddd D/MM/YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543),
  monthHeaderFormat: (date, culture, localizer) => moment(date).format('MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543),
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
    `${moment(start).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543)} - ${moment(end).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543)}`,
  dayHeaderFormat: (date, culture, localizer) => moment(date).format('dddd D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543),
  weekHeaderFormat: ({ start, end }, culture, localizer) =>
    `${moment(start).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543)} - ${moment(end).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543)}`,
  agendaHeaderFormat: ({ start, end }, culture, localizer) =>
    `${moment(start).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543)} - ${moment(end).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543)}`,
  agendaDateFormat: (date, culture, localizer) => moment(date).format('D MMMM YYYY').replace(/(\d{4})/, (year) => parseInt(year) + 543),
};


const messages = {
  allDay: 'ทั้งวัน',
  previous: 'ก่อนหน้า',
  next: 'ถัดไป',
  today: 'วันนี้',
  month: 'เดือน',
  week: 'สัปดาห์',
  day: 'วัน',
  agenda: 'กำหนดการ',
  date: 'วันที่',
  time: 'เวลา',
  event: 'กิจกรรม',
  noEventsInRange: 'ไม่มีกิจกรรมในช่วงเวลานี้',
  showMore: total => `แสดงเพิ่มเติม (${total})`
};

const CalendarView = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);

  const [filter, setFilter] = useState({
    search: '',
    type: [],
    category: [],
    department: '',
    startDate: '',
    endDate: '',
  });

  const [filterVisible, setFilterVisible] = useState(false);
  useEffect(() => {
    // Fetch event data from backend
    fetch('https://e-calendar.rmutp.ac.th/api/event_list')
      .then(response => response.json())
      .then(data => {
        // Map API response to match the event format used in the calendar
        const formattedEvents = data.map(event => ({
          id: event.event_id,
          title: event.event_name,
          event_name: event.event_name,
          event_type: event.event_type,
          event_categories: event.event_categories,
          event_department: event.event_department,
          event_place: event.event_place,
          event_detail: event.event_detail,
          event_remark: event.event_remark,
          event_link: event.event_link,
          start: moment.utc(event.event_start_datetime).tz('Asia/Bangkok').toDate(), // แปลงจาก UTC เป็นเวลาใน timezone ของประเทศไทย
          end: moment.utc(event.event_end_datetime).tz('Asia/Bangkok').toDate(), // แปลงจาก UTC เป็นเวลาใน timezone ของประเทศไทย
        }));
        setEvents(formattedEvents);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  const filteredEvents = events.filter((event) => {
    const matchName = event.event_name.toLowerCase().includes(filter.search.toLowerCase());
    const matchType = filter.type.length === 0 || filter.type.includes(event.event_type);
    const matchCategory = filter.category.length === 0 || filter.category.includes(event.event_categories);
    const matchDepartment = !filter.department || event.event_department === filter.department;
    const matchStartDate = !filter.startDate || new Date(event.start) >= new Date(filter.startDate);
    const matchEndDate = !filter.endDate || new Date(event.end) <= new Date(filter.endDate);
    return matchName && matchType && matchCategory && matchDepartment && matchStartDate && matchEndDate;
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleToggleFilter = () => {
    setFilterVisible(!filterVisible);
  };

  const handleResetFilter = () => {
    setFilter({
      search: '',
      type: [],
      category: [],
      department: '',
      startDate: '',
      endDate: '',
    });
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const eventPropGetter = (event) => {
    let backgroundColor = '';

    switch (event.event_type) {
      case 'ประชุม':
        backgroundColor = '#2ec4b6';
        break;
      case 'อบรม':
        backgroundColor = '#98c1d9';
        break;
      case 'สัมมนา':
        backgroundColor = '#a7c957';
        break;
      case 'กิจกรรม':
        backgroundColor = '#ffbf69';
        break;
      default:
        backgroundColor = '#ff99c8';
        break;
    }

    return {
      style: {
        backgroundColor, 
        color: 'black', // เปลี่ยนสีตัวหนังสือเป็นสีดำ
      },
    };
  };

  const Legend = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 3 }}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>รูปแบบกิจกรรม</Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box sx={{ backgroundColor: '#2ec4b6', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">ประชุม</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#98c1d9', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">อบรม</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#a7c957', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">สัมมนา</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#ffbf69', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">กิจกรรม</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#ff99c8', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">อื่นๆ</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  // ฟังก์ชันสำหรับสร้าง URL สำหรับ Google Calendar
  const generateGoogleCalendarURL = (event) => {
    const start = moment(event.start).format('YYYYMMDDTHHmmss');
    const end = moment(event.end).format('YYYYMMDDTHHmmss');
    
    const details = event.event_detail ? event.event_detail : 'ไม่มีรายละเอียด';
    
    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.event_name)}&dates=${start}/${end}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(event.event_place || '')}&sf=true&output=xml`;
    
    return url;
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }} 
            onClick={() => navigate('/')}
          >
            ระบบปฏิทินกลาง มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
          </Typography>
          <Button color="inherit" onClick={() => navigate('/events')}>กิจกรรมทั้งหมด</Button>
          <Button color="inherit" onClick={() => navigate('/admin-login')}>เข้าสู่ระบบ</Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: 4 }}>
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography variant="h6">ตัวกรองปฏิทิน</Typography>
            <IconButton onClick={handleToggleFilter}>
              {filterVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Collapse in={filterVisible} timeout="auto" unmountOnExit>
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>รูปแบบกิจกรรม</InputLabel>
                  <Select
                    name="type"
                    multiple
                    value={filter.type}
                    onChange={handleFilterChange}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    <MenuItem value="ประชุม">ประชุม</MenuItem>
                    <MenuItem value="อบรม">อบรม</MenuItem>
                    <MenuItem value="สัมมนา">สัมมนา</MenuItem>
                    <MenuItem value="กิจกรรม">กิจกรรม</MenuItem>
                    <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>ประเภท/หมวดหมู่</InputLabel>
                  <Select
                    name="category"
                    multiple
                    value={filter.category}
                    onChange={handleFilterChange}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    <MenuItem value="ทั่วไป">ทั่วไป</MenuItem>
                    <MenuItem value="วันสำคัญ">วันสำคัญ</MenuItem>
                    <MenuItem value="ปฏิทินการศึกษา">ปฏิทินการศึกษา</MenuItem>
                    <MenuItem value="วันหยุดราชการประจำปี">วันหยุดราชการประจำปี</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>หน่วยงานที่รับผิดชอบ</InputLabel>
                  <Select
                    name="department"
                    value={filter.department}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                    <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                    <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                    <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                    <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                    <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                    <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                    <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                    <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                    <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                    <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                    <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                    <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                    <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                    <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                    <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                    <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                    <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                    <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                    <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                    <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                    <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                    <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                    <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                    <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" style={{ marginBottom: '20px' }}>
                  <Button variant="outlined" color="secondary" onClick={handleResetFilter}>
                    รีเซ็ตตัวกรอง
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
          <Legend />
          {/* แสดงปฏิทิน */}
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            messages={messages}
            formats={formats}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, marginTop: '20px' }}
            selectable={true}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleEventClick} // คลิ๊กเลือก event
          />
           
        </Paper>
      </Container>

      {/* Dialog แสดงรายละเอียดกิจกรรม */}
      {selectedEvent && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>{selectedEvent?.event_name}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CalendarTodayIcon sx={{ marginRight: 1, color: '#42a5f5' }} />
                <Typography variant="body1" color="textPrimary">
                วันที่: {moment(selectedEvent?.start).format('DD/MM/YYYY')} เวลา: {moment(selectedEvent?.start).format('HH:mm')} - {moment(selectedEvent?.end).format('HH:mm')}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EventIcon sx={{ marginRight: 1, color: '#42a5f5' }} />
                <Typography variant="body1" color="textPrimary">
                  {selectedEvent?.event_detail ? selectedEvent.event_detail : 'ไม่มีรายละเอียด'}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PlaceIcon sx={{ marginRight: 1, color: '#ff7043' }} />
                <Typography variant="body1" color="textPrimary">
                  สถานที่: {selectedEvent?.event_place || 'ไม่ระบุสถานที่'}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CategoryIcon sx={{ marginRight: 1, color: '#66bb6a' }} />
                <Typography variant="body1" color="textPrimary">
                  ประเภท: {selectedEvent?.event_type}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LabelIcon sx={{ marginRight: 1, color: '#ab47bc' }} />
                <Typography variant="body1" color="textPrimary">
                  หมวดหมู่: {selectedEvent?.event_categories}
                </Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BusinessIcon sx={{ marginRight: 1, color: '#ffa726' }} />
                <Typography variant="body1" color="textPrimary">
                  หน่วยงาน: {selectedEvent?.event_department}
                </Typography>
              </Box>
              <Divider />

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DescriptionIcon sx={{ marginRight: 1, color: '#ff7043' }} />
                <Typography variant="body1" color="textPrimary">
                  หมายเหตุ: {selectedEvent?.event_remark}
                </Typography>
              </Box>
              <Divider />

              {/*<Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                <Typography variant="body1" color="textPrimary">
                  ลิงค์: {selectedEvent?.event_link}
                </Typography>
              </Box>*/}

              {/*{selectedEvent?.event_link && selectedEvent.event_link.trim() !== '' && selectedEvent.event_link !== '-' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                  <Typography variant="body1" color="textPrimary">
                    ลิงค์: <a href={selectedEvent.event_link} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2', textDecoration: 'none' }}>
                      ข้อมูลเพิ่มเติม
                    </a>
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                  <Typography variant="body1" color="textPrimary">
                    ลิงค์: -
                  </Typography>
                </Box>
              )}*/}
              {selectedEvent?.event_link && selectedEvent.event_link.trim() !== '' && selectedEvent.event_link !== '-' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                  <Typography variant="body1" color="textPrimary">
                    ลิงค์: <a 
                      href={selectedEvent.event_link.startsWith('http://') || selectedEvent.event_link.startsWith('https://') 
                            ? selectedEvent.event_link 
                            : `https://${selectedEvent.event_link}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ color: '#1976d2', textDecoration: 'none' }}
                    >
                      ข้อมูลเพิ่มเติม
                    </a>
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                  <Typography variant="body1" color="textPrimary">
                    ลิงค์: -
                  </Typography>
                </Box>
              )}

            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                const googleCalendarUrl = generateGoogleCalendarURL(selectedEvent);
                window.open(googleCalendarUrl, '_blank');
              }}
              color="primary"
              variant="contained"
            >
              เพิ่มในปฏิทินของฉัน
            </Button>
            <Button onClick={handleCloseDialog} color="primary">
              ปิด
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="body1">© 2024 ระบบปฏิทินกลาง - มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร</Typography>
      </Box>
    </div>
  );
};

export default CalendarView;